@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f5f6fa;
}

/* Charts */
@keyframes fillup {
  0% {
    width: 0;
  }
}

.indicator {
  will-change: width;
  animation: fillup 1s cubic-bezier(0.64, 0, 0.35, 1);
  transition: width 1s cubic-bezier(0.64, 0, 0.35, 1);
}

@keyframes fillVerticalUp {
  0% {
    height: 0;
  }
}

.indicator-vertical {
  will-change: height;
  animation: fillVerticalUp 1s cubic-bezier(0.64, 0, 0.35, 1);
  transition: height 1s cubic-bezier(0.64, 0, 0.35, 1);
}

.arrow-up {
  /* width: 0;
  height: 0;
  border-left: 32px solid transparent;
  border-right: 32px solid transparent;

  border-bottom: 64px solid #e2e8f0; */
  background-color: #e2e8f0;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

/* Tippy */
.tippy-box[data-theme~='light'] {
  background-color: white;
  border: 1px solid rgba(0, 8, 16, 0.15);
  color: black;
}

.tippy-box[data-theme~='light'][data-placement^='top']
  > .tippy-arrow::before {
  border-top-color: white;
}
.tippy-box[data-theme~='light'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: white;
}
.tippy-box[data-theme~='light'][data-placement^='left']
  > .tippy-arrow::before {
  border-left-color: white;
}
.tippy-box[data-theme~='light'][data-placement^='right']
  > .tippy-arrow::before {
  border-right-color: white;
}
